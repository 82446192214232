import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

// 验证模块
const dict = {
  zh_CN: {
    messages: {
      required: (field) => field === 'password' ? '重复密码不能为空' : field + '不能为空',
      numeric: (field) => field + '必须是数字',
      length: (field, param) => field + '必须是' + param + '位数',
      min: (field, param) => field + '不能小于' + param + '位数',
      max: (field, param) => field + '不能大于' + param + '位数',
      confirmed: () => '两次输入的密码不一致',
      mobile: () => '必须是合法的11位手机号码'
    },
    attributes: {
      cardAccount: '卡号',
      celPhone: '手机号',
      captcha: '验证码',
      password: '密码',
			alipay_account: '支付宝帐号',
			celInviteCode: '邀请码'
    },
    custom: {
      license: {
        required: '必须同意协议'
      }
    }
  }
}

// 引入到Vue
Vue.use(VeeValidate, {
  errorBagName: 'errors', // change if property conflicts.
  locale: 'zh_CN',
  dictionary: dict
})

Validator.extend('mobile', {
  validate: value => {
    // return value.length == 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(value)
    return /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(value)
  }
})
